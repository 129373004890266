<template>
  <div class="buildings_list col-12 p-4">
    <div class="content-box row col-md-12 col-lg-8">
      <div class="col-12">
        <header>
          <h2
            class="mt-3 egx-partner-color"
            :class="buildings.title.class"
            :style="buildings.title.style"
          >
            {{ buildings.title.text }}
          </h2>
          <p
            :class="buildings.description.class"
            :style="buildings.description.style"
            v-html="buildings.description.text"
          />
        </header>
      </div>
      <div
        class="section-template building-list-container"
        :class="{'swap-places': buildings.swap_sections}"
      >
        <div
          class="section-form-box section-form-title"
          v-if="!partner.settings.disable_new_building_button && !disable_building_edition"
        >
          <h4
            :class="buildings.sub_sections.building_new.title.class"
            :style="buildings.sub_sections.building_new.title.style"
          >
            {{ buildings.sub_sections.building_new.title.text }}
          </h4>
          <br />
          <div class="section-form-box add-new-wrapper">
            <div @click="newBuildingPage()" class="add-new d-flex align-items-center">
              <span class="icon d-flex justify-content-center align-items-center">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </span>
              <span
                class="title"
                :class="buildings.sub_sections.building_new.description.class"
                :style="buildings.sub_sections.building_new.description.style"
              >
                {{ buildings.sub_sections.building_new.description.text }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="section-form-box"
          v-if="hasAnyBuilding"
        >
        <FixedModal :show_modal="loading">
          <div class="m-4 text-center">
            <font-awesome-icon
            :icon="['fa', 'circle-notch']"
            class="fa-spin m-auto spinner fa-4x"
            :class="'egx-partner-color'"
            />
          </div>
        </FixedModal>
        <h1
            class="font-size-h4"
            :class="buildings.sub_sections.buildings_list.title.class"
            :style="buildings.sub_sections.buildings_list.title.style"
          >
            {{ buildings.sub_sections.buildings_list.title.text }}
          </h1>
          <br>
          <Pagination
            v-if="pageSetupInfo.perPage < pagination.totalRecords"
            :page="pageSetupInfo.page"
            :perPage="Number(pagination.totalDisplayRecords)"
            :totalRecords="pagination.totalRecords"
            @page-change="changePagination"
          >
          <div
            class="section-form-box building-list d-md-flex mb-3"
            v-for="building in allBuilding" :key="building.id"
          >
            <div
              class="
                d-flex
                building-list-icon-wrapper
                flex-column
                align-items-center
                justify-content-center
              "
              :style="buildingListIconSettings.style"
              :class="buildingListIconSettings.class"
            >
              <font-awesome-icon
                :icon="['fal', buildingListIconSettings.icon || 'house']" class="home-icon"
              />
            </div>
            <div
              class="d-flex flex-column align-self-center egx-partner-color add-new pl-4"
              @click="editBuilding(building.id)"
            >
              <h5 class="d-sm-block" v-html="buildingTitle(building)" />
              <span class="mb-3 mb-md-0" notranslate>
                {{ getBuildingAddress(building.attributes, addressElements) }}
              </span>
            </div>
            <div class="buttons d-flex flex-column justify-content-center ml-auto">
              <template v-if="is_assessment_enabled">
                <b-button
                  @click="
                    redirectOtherSettings(
                      building.id,
                      buildings.sub_sections.buildings_list.overview_button.path_name
                    )
                  "
                  class="btn btn-building btn-egx"
                  :class="buildings.sub_sections.buildings_list.overview_button.class"
                  :style="buildings.sub_sections.buildings_list.overview_button.style"
                  v-if="auditComplete(building.id)"
                >
                  {{ buildings.sub_sections.buildings_list.overview_button.text }}
                </b-button>

                <b-button
                  @click="
                    redirectOtherSettings(
                      building.id,
                      buildings.sub_sections.buildings_list.show_button.path_name
                    )
                  "
                  class="btn btn-building btn-egx"
                  :class="buildings.sub_sections.buildings_list.show_button.class"
                  :style="buildings.sub_sections.buildings_list.show_button.style"
                  v-else
                >
                  {{ buildings.sub_sections.buildings_list.show_button.text }}
                </b-button>
              </template>

              <button
                @click="redirectToIncentives(building.id)"
                class="btn btn-building mt-1 btn-egx-white"
                :class="buildings.sub_sections.buildings_list.incentive_button.class"
                :style="buildings.sub_sections.buildings_list.incentive_button.style"
                v-if="is_incentive_enabled"
                v-html="toggleIncentiveBtnTxt(building.id)"
              />
            </div>
          </div>
        </Pagination>
        <div v-else>
          <br />
          <div
            class="section-form-box building-list d-md-flex mb-3"
            v-for="building in allBuilding" :key="building.id"
          >
            <div
              class="
                d-flex
                building-list-icon-wrapper
                flex-column
                align-items-center
                justify-content-center
              "
              :style="buildingListIconSettings.style"
              :class="buildingListIconSettings.class"
            >
              <font-awesome-icon
                :icon="['fal', buildingListIconSettings.icon || 'house']" class="home-icon"
              />
            </div>
            <div
              class="d-flex flex-column align-self-center egx-partner-color add-new pl-4"
              @click="editBuilding(building.id)"
            >
              <h5 class="d-sm-block" v-html="buildingTitle(building)" />
              <span class="mb-3 mb-md-0" notranslate>
                {{ getBuildingAddress(building.attributes, addressElements) }}
              </span>
            </div>
            <div class="buttons d-flex flex-column justify-content-center ml-auto">
              <template v-if="is_assessment_enabled">
                <b-button
                  @click="
                    redirectOtherSettings(
                      building.id,
                      buildings.sub_sections.buildings_list.overview_button.path_name
                    )
                  "
                  class="btn btn-building btn-egx"
                  :class="buildings.sub_sections.buildings_list.overview_button.class"
                  :style="buildings.sub_sections.buildings_list.overview_button.style"
                  v-if="auditComplete(building.id)"
                >
                  {{ buildings.sub_sections.buildings_list.overview_button.text }}
                </b-button>

                <b-button
                  @click="
                    redirectOtherSettings(
                      building.id,
                      buildings.sub_sections.buildings_list.show_button.path_name
                    )
                  "
                  class="btn btn-building btn-egx"
                  :class="buildings.sub_sections.buildings_list.show_button.class"
                  :style="buildings.sub_sections.buildings_list.show_button.style"
                  v-else
                >
                  {{ buildings.sub_sections.buildings_list.show_button.text }}
                </b-button>
              </template>

              <button
                @click="redirectToIncentives(building.id)"
                class="btn btn-building mt-1 btn-egx-white"
                :class="buildings.sub_sections.buildings_list.incentive_button.class"
                :style="buildings.sub_sections.buildings_list.incentive_button.style"
                v-if="is_incentive_enabled"
                v-html="toggleIncentiveBtnTxt(building.id)"
              />
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '../router/index';
import BuildingAddress from '../shared/mixins/building-address';
import ObjectFormater from '../shared/mixins/objectFormater';
import Pagination from '../components/Pagination.vue';
import FixedModal from '../components/modals/FixedModal.vue';

export default {
  name: 'BuildingsList',

  mixins: [BuildingAddress, ObjectFormater],

  components: {
    Pagination,
    FixedModal,
  },

  data() {
    return {
      addressElements: ['address2', 'city', 'community'],
      pageSetupInfo: {
        totalRecords: null,
        page: 0,
        first: 0,
        perPage: this.$store.state.partners.currentPartner.settings.buildings_listed_per_page || 10,
        sortField: null,
        sortOrder: null,
      },
      loading: false,
    };
  },

  created() {
    this.$store.dispatch('buildings/setNew');
    this.$store.dispatch('buildings/getAll', { ...this.keysToSnakeCase(this.pageSetupInfo) }).then(() => {
      console.log('All buildings fetched!!');
    }).catch((error) => {
      if (error.response) {
        console.log(error.response.data.errors);
      }
    });
  },

  computed: {
    useOffer() {
      return this.partner.settings.energyxpert_admin_enabled;
    },

    buildings() {
      return this.$store.state.partners.currentPartner.settings.sections.buildings_list.buildings;
    },

    allBuilding() {
      return this.$store.state.buildings.allBuilding;
    },

    sections() {
      return this.$store.state.partners.currentPartner.settings.sections.audit_sections;
    },

    hasAnyBuilding() {
      return !!this.allBuilding && this.allBuilding.length > 0;
    },

    is_incentive_enabled() {
      return this.$store.state.partners.currentPartner.settings.enable_incentives;
    },

    is_assessment_enabled() {
      return this.$store.state.partners.currentPartner.settings.enable_assessment;
    },

    partner() {
      return this.$store.state.partners.currentPartner;
    },

    buildingListIconSettings() {
      return this.buildings.sub_sections.buildings_list.icon || {};
    },

    pagination() {
      console.log(this.partner.settings.disable_building_profile_edition);
      return this.$store.state.buildings.paginatedBuildings;
    },
    disable_building_edition() {
      return this.partner.settings.disable_building_profile_edition || this.isFulFillSync;
    },

    isFulFillSync() {
      return this.$store.state.users.currentUser.extra_info?.is_fulfill_sync;
    }
  },

  methods: {
    redirectToIncentives(buildingId) {
      this.$store.dispatch('buildings/setCurrentBuildingId', buildingId);
      router.push({ name: 'incentives' });
    },

    setAllSectionData(buildingId) {
      const sections = Object.values(this.sections);

      this.$store.dispatch(
        'generic_items/setGenericItemsSectionData',
        { sections, buildingId },
      );
    },

    editBuilding(buildingId) {
      router.push(`/buildings/${buildingId}`);
    },

    newBuildingPage() {
      router.push({ name: 'building-details' });
    },

    redirectOtherSettings(buildingId, path) {
      this.$store.dispatch('generic_items/clean')
        .then(async () => {
          await this.$store.dispatch('virtual_audits/clean');
        })
        .then(async () => {
          await this.$store.dispatch('buildings/set', buildingId);
        })
        .then(async () => {
          await this.$store.dispatch('generic_items/setAllCurrentGenericItems');
        })
        .then(async () => {
          await this.setAllSectionData(buildingId);
        })
        .then(() => {
          console.log(`Current building: ${buildingId}`);
          router.push({ name: path }).catch(()=>{});
        })
        .catch((error) => {
          if (error.response) {
            this.$store.dispatch('buildings/setNew');
            router.push({ name: 'building-details' });
          }
        });
    },

    buildingTitle(building) {
      let titleHTML = '';
      const name = building.attributes.extra_info.building_name || '';
      const otherName = building.attributes.extra_info.building_other_name;
      const address = building.attributes.address1;

      if (name === 'Other' && otherName !== '') {
        titleHTML += `<span isolate>${name}</span><span notranslate> - ${otherName}</span`;
      } else {
        titleHTML += `<span isolate>${name}</span>`;
      }

      if (name !== undefined && name !== '') {
        titleHTML += `<span notranslate> - ${address}</span>`;
      } else {
        titleHTML = `<span notranslate>${address}</span>`;
      }

      return titleHTML;
    },

    fetchBuilding(buildingId) {
      return this.allBuilding.find(
        (obj) => obj.id === buildingId,
      );
    },

    auditComplete(buildingId) {
      const building = this.fetchBuilding(buildingId);

      return building && !!building.attributes.extra_info.virtual_audit;
    },

    totalCount(buildingId) {
      return this.fetchBuilding(buildingId).attributes.total_project_update_requested;
    },

    toggleIncentiveBtnTxt(buildingId) {
      let btnTxt = '';
      const msgCount = this.totalCount(buildingId);

      if (msgCount > 0) {
        btnTxt = `<span>View messages (<var message-count>${msgCount}</var>)</span>`;
      } else {
        btnTxt = this.buildings.sub_sections.buildings_list.incentive_button.text;
      }

      return btnTxt;
    },
    changePagination(page) {
      const storeType = this.useOffer ? 'offers/getAll' : 'subsidies/getAll';
      this.pageSetupInfo.page = page;
      this.loading = true;

      this.$store.dispatch('buildings/getAll', { ...this.keysToSnakeCase(this.pageSetupInfo) }).then(() => {
        console.log('All buildings fetched!!');
      }).then(() => {
        this.$store.dispatch(storeType);
        this.loading = false;
      }).catch((error) => {
        if (error.response) {
          console.log(error.response.data.errors);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/stylesheets/pages/buildings_list';
</style>
